import appsApi from "./admin.api";
import utils from "@/helpers/utilidades";

export default {

    index(modulo) {
        return appsApi.api.get(utils.url_options(`${appsApi.baseURL}/aplicaciones/modulos/${modulo}/roles`));
    },

    rol(modulo, rol) {
        return appsApi.api.get(utils.url_options(`${appsApi.baseURL}/aplicaciones/modulos/${modulo}/roles/${rol}`));
    },

    crear(modulo,payload) {
        return appsApi.api.post(`${appsApi.baseURL}/aplicaciones/modulos/${modulo}/roles`, payload)
    },

    editar(modulo, rol, payload) {
        return appsApi.api.put(`${appsApi.baseURL}/aplicaciones/modulos/${modulo}/roles/${rol}`, payload)
    },

    eliminar(modulo, rol) {
        return appsApi.api.delete(`${appsApi.baseURL}/aplicaciones/modulos/${modulo}/roles/${rol}`)
    },

    crear_permiso(rol, payload) {
        return appsApi.api.post(`${appsApi.baseURL}/aplicaciones/modulos/roles/${rol}/permisos`, payload)
    },

    editar_permiso(rol, permiso, payload) {
        return appsApi.api.put(`${appsApi.baseURL}/aplicaciones/modulos/roles/${rol}/permisos/${permiso}`, payload)
    },

    eliminar_permiso(rol, permiso) {
        return appsApi.api.delete(`${appsApi.baseURL}/aplicaciones/modulos/roles/${rol}/permisos/${permiso}`)
    },

}
